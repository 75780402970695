var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"common"}},_vm._l((_vm.pages),function(page){return _c('div',{key:'page' + page,staticClass:"page",attrs:{"size":"A4"}},[_c('v-row',{staticClass:"pt-1 align-center"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{attrs:{"size":"40"}},[_c('img',{attrs:{"src":"https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"}})])],1),_c('v-col',{staticStyle:{"line-height":"12pt"}},[_c('b',{staticStyle:{"font-size":"15px"}},[_vm._v("Rede Casas das Tintas")])]),_c('v-col',{staticClass:"text-right"},[_vm._v(" Extrato de Débitos "+_vm._s(_vm.date)+" ")])],1),_c('hr',{staticClass:"mt-2 mb-4"}),_c('v-row',{staticClass:"d-flex justify-space-between align-center"},[_c('v-col',{attrs:{"cols":"7"}},[_c('b',[_vm._v("Dados Do Cliente")]),_c('div',[_c('span',[_c('b',[_vm._v("Nome:")]),_vm._v(" "+_vm._s(_vm.debit_statement.customer.code)+" - "+_vm._s(_vm.debit_statement.customer.name)+" ")])])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"page":page,"headers":_vm.headers,"items":_vm.getItems(page),"items-per-page":_vm.itensPerPage,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){
var item = ref.item;
return [(item.origin == 'sale')?_c('span',[_vm._v(" "+_vm._s(item.sale.number)+" ")]):_vm._e(),(item.origin != 'sale')?_c('span',[_vm._v(" "+_vm._s(item.document)+" ")]):_vm._e()]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.issue_date))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.dateBr(item.due_date))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.value))+" ")]):_vm._e()]}},{key:"item.remainder_value",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.remainder_value))+" ")]):_vm._e()]}},{key:"item.interest_to_pay",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(_vm.$format.decimal(item.interest_to_pay))+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{staticClass:"text-right pr-3",attrs:{"cols":"6"}},[_c('div',[_c('b',[_vm._v(" Total Original: ")]),_vm._v(" "+_vm._s(_vm.$format.moneyBr(_vm.totals.value_total))+" ")]),_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(" Saldo Devedor: ")]),_vm._v(_vm._s(_vm.$format.moneyBr(_vm.totals.remainder_total))+" ")]),_c('div',{staticClass:"my-1"},[_c('b',[_vm._v(" Juros a PG: ")]),_vm._v(" "+_vm._s(_vm.$format.moneyBr(_vm.totals.interest_to_pay))+" ")]),_c('div',[_c('b',[_vm._v(" Total a PG: ")]),_vm._v(_vm._s(_vm.$format.moneyBr(_vm.totalToPay)))])])],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }