<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-1 align-center">
        <v-col cols="1">
          <v-avatar size="40">
            <img
              src="https://links.casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
        </v-col>
        <v-col style="line-height: 12pt">
          <b style="font-size: 15px">Rede Casas das Tintas</b>
        </v-col>

        <v-col class="text-right">
          Extrato de Débitos
          {{ date }}
        </v-col>
      </v-row>
      <hr class="mt-2 mb-4" />

      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="7">
          <b>Dados Do Cliente</b>
          <div>
            <span>
              <b>Nome:</b>
              {{ debit_statement.customer.code }} -
              {{ debit_statement.customer.name }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :page="page"
            :headers="headers"
            :items="getItems(page)"
            :items-per-page="itensPerPage"
            hide-default-footer
            disable-sort
            dense
          >
            <template v-slot:[`item.document`]="{ item }">
              <span v-if="item.origin == 'sale'">
                {{ item.sale.number }}
              </span>
              <span v-if="item.origin != 'sale'">
                {{ item.document }}
              </span>
            </template>
            <template v-slot:[`item.issue_date`]="{ item }">
              {{ $format.dateBr(item.issue_date) }}
            </template>
            <template v-slot:[`item.due_date`]="{ item }">
              {{ $format.dateBr(item.due_date) }}
            </template>

            <template v-slot:[`item.value`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.value) }}
              </span>
            </template>
            <template v-slot:[`item.remainder_value`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.remainder_value) }}
              </span>
            </template>
            <template v-slot:[`item.interest_to_pay`]="{ item }">
              <span v-if="item.id">
                {{ $format.decimal(item.interest_to_pay) }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end">
        <v-col cols="6" class="text-right pr-3">
          <div>
            <b> Total Original: </b> {{ $format.moneyBr(totals.value_total) }}
          </div>
          <div class="my-1">
            <b> Saldo Devedor: </b>{{ $format.moneyBr(totals.remainder_total) }}
          </div>
          <div class="my-1">
            <b> Juros a PG: </b> {{ $format.moneyBr(totals.interest_to_pay) }}
          </div>
          <div><b> Total a PG: </b>{{ $format.moneyBr(totalToPay) }}</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";

export default {
  props: {
    debit_statement: {},
  },
  data() {
    return {
      date: format(new Date(), "dd/MM/yyyy"),

      itensPerPage: 20,

      headers: [
        { text: "Documento", value: "document", align: "center" },
        { text: "Emissão", value: "issue_date", align: "center" },
        { text: "Vencimento", value: "due_date", align: "center" },
        { text: "Valor Original", value: "value", align: "center" },
        { text: "Saldo Devedor", value: "remainder_value", align: "center" },
        { text: "Juros a Pg", value: "interest_to_pay", align: "center" },
      ],
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    bills() {
      return this.debit_statement.bills;
    },

    totals() {
      return this.debit_statement;
    },

    totalToPay() {
      return (
        parseFloat(this.totals.interest_to_pay) +
        parseFloat(this.totals.remainder_total)
      );
    },

    itensCount() {
      if (this.bills) {
        return this.bills.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },

    getItems(page) {
      let items = this.bills.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      if (items.length < this.itensPerPage) {
        for (let i = items.length; i < this.itensPerPage; i++) {
          items.push({});
        }
      }
      return items;
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>